export * from './setDarkstore';
export * from './setModalIndex';
export * from './openDarkstores';
export * from './addressNotDarkstore';
export * from './setReCaptchaValue';
export * from './setOpenSideBar';
export const _getDarkstores = () => import('./getDarkstores');
export const _getDarkstore = () => import('./getDarkstore');
export const _verifyCpf = () => import('./verifyCpf');
export const _oxxoGetAddress = () => import('./oxxoGetAddress');
export const _gnCancelOrder = () => import('./gnCancelOrder');
export const _listInventories = () => import('./listInventories');
export const _getGoogleGeolocation = () => import('./getGoogleGeolocation');
export const _gnExcludeAccount = () => import('./gnExcludeAccount');
export const _gnOneClick = () => import('./gnOneClick');
export const _getUserIP = () => import('./getUserIP');
export const _getActiveOrders = () => import('./getActiveOrders');
export const _clearActiveOrders = () => import('./clearActiveOrders');
export const _gnValidationCaptcha = () => import('./gnValidationCaptcha');
export const _getUserPostalCode = () => import('./getUserPostalCode');
export const _getGoogleGeolocationDistance = () => import('./getGoogleGeolocationDistance');
export const _getProductsInventories = () => import('./getProductsInventories');
export const _getCancelMotives = () => import('./getCancelMotives');
