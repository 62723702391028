export const OXXOProfileRegistration = () => import('./profile/oxxo-profile-registration');
export const OXXOProfileRegistrationContainer = () => import('./profile/oxxo-profile-registration-container');
export const OXXOFooterContainerLinks = () => import('./footer/oxxo-footer-container-links');
export const OXXOFooterContact = () => import('./footer/oxxo-footer-contact');
export const OXXOFooterPayments = () => import('./footer/oxxo-footer-payments');
export const OXXOFooterBottom = () => import('./footer/oxxo-footer-bottom');
export const OXXOCommonSearchBoxDesktop = () => import('./common/oxxo-common-search-box-desktop');
export const OXXOHeaderMiniCartDesktop = () => import('./header/oxxo-header-mini-cart/desktop');
export const OXXOHeaderMiniCartMobile = () => import('./header/oxxo-header-mini-cart/mobile');
export const OXXOCommonImage = () => import('./common/oxxo-common-image');
export const OXXOFooterLinksList = () => import('./footer/oxxo-footer-links-list');
export const OXXOProductInventoryStatus = () => import('./product/oxxo-product-inventory-status');
export const OXXOComponentsContainer = () => import('./common/oxxo-components-container');
export const OXXOCommonSearchBoxMobile = () => import('./common/oxxo-common-search-box-mobile');
export const OXXOProductProductSocialSharing = () => import('./product/oxxo-product-product-social-sharing');
export const OXXOProductAddToCartButton = () => import('./product/oxxo-product-add-to-cart-button');
export const OXXOCheckoutContinueToPaymentButton = () => import('./checkout/oxxo-checkout-continue-to-payment-button');
export const OXXOCartItemDetails = () => import('./cart/oxxo-cart-item-details');
export const OXXOProductListingProductResultImage = () =>
  import('./product-listing/oxxo-product-listing-product-result-image');
export const OXXOCategoryImages = () => import('./category/oxxo-category-images');
export const OXXOProductName = () => import('./product/oxxo-product-name');
export const OXXOProductPrice = () => import('./product/oxxo-product-price');
export const OXXOHeaderGeolocation = () => import('./header/oxxo-header-geolocation');
export const OXXOProductListingDynamicProductListingContainer = () =>
  import('./product-listing/oxxo-product-listing-dynamic-product-listing-container');
export const OXXOCheckoutCreditCard = () => import('./checkout/oxxo-checkout-credit-card');
export const OXXOCartOrderSummary = () => import('./cart/oxxo-cart-order-summary');
export const OXXOProfileAddressBookSummary = () => import('./profile/oxxo-profile-address-book-summary');
export const OXXOProfileSavedCardSummary = () => import('./profile/oxxo-profile-saved-card-summary');
export const OXXOCheckoutShippingInformation = () => import('./checkout/oxxo-checkout-shipping-information');
export const OXXOCheckoutSingleShippingDetails = () => import('./checkout/oxxo-checkout-single-shipping-details');
export const OXXOCommonErro500 = () => import('./common/oxxo-common-erro-500');
export const OXXOProfileUpdateExpiredPassword = () => import('./profile/oxxo-profile-update-expired-password');
export const OXXOProfileDetails = () => import('./profile/oxxo-profile-details');
export const OXXOProfileDetailsSummary = () => import('./profile/oxxo-profile-details-summary');
export const OXXOProfileUpdatePassword = () => import('./profile/oxxo-profile-update-password');
export const OXXOProfileOrderInformation = () => import('./profile/oxxo-profile-order-information');
export const OXXOFooterLinksListCheckout = () => import('./footer/oxxo-footer-links-list-checkout');
export const OXXOFooterBottomCheckout = () => import('./footer/oxxo-footer-bottom-checkout');
export const OXXOProfilePurchaseLists = () => import('./profile/oxxo-profile-purchase-lists');
export const OXXOProfilePurchaseListInformation = () => import('./profile/oxxo-profile-purchase-list-information');
export const OXXOProfilePurchaseListActionButtons = () => import('./profile/oxxo-profile-purchase-list-action-buttons');
export const OXXOProfileCreatePurchaseList = () => import('./profile/oxxo-profile-create-purchase-list');
export const OXXOProductAddToPurchaselist = () => import('./profile/oxxo-product-add-to-purchase-list');
export const OXXOProfileOrderDetailsCopyOrder = () => import('./profile/oxxo-profile-order-details-copy-order');
export const OXXOProfileResetPassword = () => import('./profile/oxxo-profile-reset-password');
export const LoginContainer = () => import('./login/oxxo-login-container');
export const OXXOLogin = () => import('./login/oxxo-login');
export const OXXOCheckoutOrderConfirmationContainer = () =>
  import('./checkout/oxxo-checkout-order-confirmation-container');
export const OXXOCartProductReplacement = () => import('./cart/oxxo-cart-product-replacement');
export const OXXOHeaderContainerDesktop = () => import('./header/oxxo-header-container/desktop');
export const OXXOHeaderContainerMobile = () => import('./header/oxxo-header-container/mobile');
export const OXXOProductLongDescription = () => import('./product/oxxo-product-long-description');
export const OXXOProductShortDescription = () => import('./product/oxxo-product-short-description');
export const OXXOProfileAddCreditCard = () => import('./profile/oxxo-profile-add-credit-card');
export const OXXOProfileSavedCards = () => import('./profile/oxxo-profile-saved-cards');
export const OXXOCommonError404 = () => import('./common/oxxo-common-error-404');
export const OXXOProductListingProductCategoryName = () =>
  import('./product-listing/oxxo-product-listing-product-category-name');
export const OXXOCheckoutPix = () => import('./checkout/oxxo-checkout-pix');
export const OXXOCheckoutOrderConfirmation = () => import('./checkout/oxxo-checkout-order-confirmation');
export const OXXOProductListingFacetedNavigation = () =>
  import('./product-listing/oxxo-product-listing-faceted-navigation');
export const OXXOProductListingFacetedNavigationMobile = () =>
  import('./product-listing/oxxo-product-listing-faceted-navigation/mobile');
export const OXXOProfileOrderSummary = () => import('./profile/oxxo-profile-order-summary');
export const OXXOCheckoutReviewOrderContainer = () => import('./checkout/oxxo-checkout-review-order-container');
export const OXXOCheckoutPlaceOrderButton = () => import('./checkout/oxxo-checkout-place-order-button');
export const OXXOCommonCategory = () => import('./common/oxxo-common-category');
export const OXXOProfileCancelOrder = () => import('./profile/oxxo-profile-cancel-order');
export const OXXOProfileOrderActions = () => import('./profile/oxxo-profile-order-actions');
export const OXXOCheckoutReviewOrderPaymentInformation = () =>
  import('./checkout/oxxo-checkout-review-order-payment-information');
export const OXXOFooterBottomMenu = () => import('./footer/oxxo-footer-bottom-menu');
export const OXXOFooterFinalizePurchase = () => import('./footer/oxxo-footer-finalize-purchase');
export const OXXOProfileAddressForm = () => import('./profile/oxxo-profile-address-form');
export const OXXOCommonDynamicBreadcrumbs = () => import('./common/oxxo-common-dynamic-breadcrumbs');
export const OXXOCommonCookieControl = () => import('./common/oxxo-common-cookie-control');
export const OXXOProfileAddressBook = () => import('./profile/oxxo-profile-address-book');
export const OXXOProductVariantOptions = () => import('./product/oxxo-product-variant-options');
export const OXXOCategoryCarousel = () => import('./category/oxxo-category-carousel');
export const OXXOCheckoutLogin = () => import('./checkout/oxxo-checkout-login');
export const OXXOProfilePaymentInformation = () => import('./profile/oxxo-profile-payment-information');
export const OXXOProductResultPrice = () => import('./product-listing/oxxo-product-result-price');
export const OXXOCheckoutShippingContainer = () => import('./checkout/oxxo-checkout-shipping-container');
export const OXXOHeaderCollectionNavigationDesktop = () => import('./header/oxxo-header-collection-navigation-desktop');
export const OXXOManualRecipesCarousel = () => import('./common/oxxo-common-manual-recipes-product-carousel');
export const OXXOProductQuantity = () => import('./product/oxxo-product-quantity');
export const OXXOCartPromotionCodeEntry = () => import('./cart/oxxo-cart-promotion-code-entry');
export const OXXOProductListingDynamicProductList = () =>
  import('./product-listing/oxxo-product-listing-dynamic-product-list');
export const OXXOProfileOrderHistory = () => import('./profile/oxxo-profile-order-history');
export const OXXOCheckoutPaymentsContainer = () => import('./checkout/oxxo-checkout-payments-container');
export const OXXOProfileEmailMarketingPreferences = () => import('./profile/oxxo-profile-email-marketing-preferences');
export const OXXOCheckoutButton = () => import('./checkout/oxxo-checkout-button');
export const OXXOLoginGoogle = () => import('./login/oxxo-login-google');
export const OXXOLoginFacebook = () => import('./login/oxxo-login-facebook');
export const OXXOCheckoutScheduled = () => import('./checkout/oxxo-checkout-scheduled');
export const OXXOProductProductDetailsContainer = () => import('./product/oxxo-product-details-container');
export const OXXOProfilePurchaseListFavoritesCarousel = () =>
  import('./profile/oxxo-profile-purchase-list-favorites-carousel');
export const OXXOProfileRecentOrders = () => import('./profile/oxxo-profile-recent-orders');
export const OXXOHeaderDarkstoreTopbar = () => import('./header/oxxo-header-darkstore-topbar');
export const OXXOCommonVerticalBanner = () => import('./common/oxxo-common-vertical-banner');
export const OXXOProfileExcludeAccount = () => import('./profile/oxxo-profile-exclude-account');
export const OXXOCommonImageCarousel = () => import('./common/oxxo-common-image-carousel');
export const OXXOProfilePurchaseListsSummary = () => import('./profile/oxxo-profile-purchase-lists-summary');
export const OXXOProductRecipeSharing = () => import('./product/oxxo-product-recipe-sharing');
export const OXXOCommonRecommendationsCarouselSettings = () =>
  import('./common/oxxo-common-recommendations-carousel-settings');
export const OXXOCollectionNavigation = () => import('./header/oxxo-collection-navigation');
export const OXXOMenuMobile = () => import('./header/oxxo-menu-mobile');
export const OXXOUserProfileLinksDesktop = () => import('./header/oxxo-user-profile-links/desktop');
export const OXXOUserProfileLinksMobile = () => import('./header/oxxo-user-profile-links/mobile');
export const OXXOCartPromotionsDisplay = () => import('./cart/oxxo-cart-promotions-display');
export const OXXOCheckoutContinueToReviewOrderButton = () =>
  import('./checkout/oxxo-checkout-continue-to-review-order-button');
export const OXXOCommonBackButton = () => import('./common/oxxo-common-back-button');
export const OXXOCartContainer = () => import('./cart/oxxo-cart-container');
export const OXXOContinueShoppingButton = () => import('./common/oxxo-continue-shopping-button');
export const OXXOLoginApple = () => import('./login/oxxo-login-apple');
export const OXXOHeaderGeolocationMobile = () => import('./header/oxxo-header-geolocation-mobile');
export const OXXOCartProgressiveBar = () => import('./cart/oxxo-cart-progressive-bar');
export const OXXOCommonNotifications = () => import('./common/oxxo-common-notifications');
export const OXXOCommonMobileReview = () => import('./common/oxxo-common-mobile-review');
